import { useEffect, useState } from 'react';
import { getFallbackPopularCities } from 'utils/get-fallback-popular-cities-by-country';
import { useUserContext } from 'contexts/user';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';
import { fetchWithRetry } from 'utils/fetchWithRetry';
import { getInsightsHost, isServerSide } from 'utils/host-config';
import { HttpRequestMethodTypes } from 'types';
import defaultPopularCitiesData from 'components/suggested-location-dropdown/data.json';
import { TopCitiesResponse } from '@zoocasa/go-search';
import { CountryCodeList } from 'types/countries';
import { countryCodeFromProvinceOrState } from 'utils/province_or_state';
import Cookies from 'js-cookie';
import { POPULAR_CITIES_COOKIE } from 'constants/cookies';

import type { SearchSuggestions } from 'components/suggested-location-dropdown';
import type { IUserLocation } from 'utils/user-location';

/**
 * A single custom hook that:
 * - Uses the user context for user location, site location, etc.
 * - Decides whether to fetch city data (if userLocationSameAsSiteLocation but none is stored).
 * - Provides fallback data if fetch fails or if siteLocation differs.
 */
export default function usePopularCities() {
  const {
    userLocationSameAsSiteLocation,
    userPopularCities,
    userLocation,
    siteLocation,
  } = useUserContext();
  const { themeName } = useThemeContext();

  const [popularCities, setPopularCities] = useState<SearchSuggestions[]>([]);

  useEffect(() => {
    async function fetchAndSetPopularCities() {
      // 1) Already have them in user context
      if (userLocationSameAsSiteLocation && userPopularCities?.length) {
        setPopularCities(userPopularCities);
        return;
      }

      // 2) Need to fetch them if site == user location but none stored yet
      if (
        userLocationSameAsSiteLocation &&
        !userPopularCities?.length &&
        userLocation &&
        Object.keys(userLocation).length > 0
      ) {
        const fetched = await fetchPopularCities(userLocation, siteLocation);
        setPopularCities(fetched);
        return;
      }

      // 3) Fallback logic based on theme/country
      const fallbackCities = getFallbackPopularCities(themeName as ThemeNames, siteLocation);
      setPopularCities(fallbackCities);
    }

    fetchAndSetPopularCities();
  }, [
    userPopularCities,
    userLocation,
    siteLocation,
    userLocationSameAsSiteLocation,
    themeName,
  ]);

  return popularCities;
}

/**
 * Fetch city data from the server using the user’s location info
 */
async function fetchPopularCities(
  location: IUserLocation,
  siteLocation?: CountryCodeList
): Promise<SearchSuggestions[]> {
  const { usPopularSearches, canadaPopularSearches } = defaultPopularCitiesData;

  async function getCities(countryCode: string, provinceCode?: string) {
    const provinceUrlSegment = provinceCode ? `/${provinceCode}` : '';
    const areaPageUrl = `${getInsightsHost(isServerSide())}/insights/popular/${countryCode}${provinceUrlSegment}?limit=10`;

    try {
      const response = await fetchWithRetry(areaPageUrl, { method: HttpRequestMethodTypes.GET });
      if (!response.ok) {
        console.error('Failed to fetch popular cities:', response.status, response.statusText);
        return [];
      }

      const content = await response.blob();
      const buffer = await content.arrayBuffer();
      const topCities = TopCitiesResponse.decode(new Uint8Array(buffer)).topCities;

      return topCities.slice(0, 10).map((c, index) => {
        const cityProvinceName = c.Slug.slice(-2);
        return {
          identifier: index,
          label: `${c.Name}, ${cityProvinceName?.toUpperCase()} - City`,
          urlPath: `${c.Slug}-real-estate`,
          zoom: 14,
          hasAreaPage: true,
        } as SearchSuggestions;
      });
    } catch (error) {
      console.error('Failed to fetch popular cities:', error);
      // fallback to local defaults
      return siteLocation === CountryCodeList.UNITED_STATES
        ? usPopularSearches
        : canadaPopularSearches;
    }
  }

  // 1. Attempt with both country & province
  const provinceCode = location?.slug?.split('-').pop() ?? '';
  let cities = await getCities(location.countryCode, provinceCode);

  // 2. If empty, retry with just the country
  if (!cities?.length) {
    cities = await getCities(location.countryCode);
  }

  // 3. Filter out results that don't match siteLocation's country code
  const citiesWithSameSiteLocation = cities?.filter(city => {
    const province = city.urlPath.split('-')[1]; // e.g. "ny" from "new-york-ny-real-estate"
    return countryCodeFromProvinceOrState(province) === siteLocation;
  }) || [];

  // 4. If there are fewer than 10, add fallback ones
  const numberOfAdditionalCitiesNeeded = Math.max(0, 10 - citiesWithSameSiteLocation.length);
  if (numberOfAdditionalCitiesNeeded > 0) {
    const fallback =
      siteLocation === CountryCodeList.CANADA
        ? canadaPopularSearches
        : usPopularSearches;
    const additionalCities = fallback.slice(0, numberOfAdditionalCitiesNeeded);
    cities = [...cities, ...additionalCities];
  }

  Cookies.set(POPULAR_CITIES_COOKIE, JSON.stringify(cities));
  return cities;
}
