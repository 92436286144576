import { ThemeNames } from 'types/themes';
import { CountryCodeList } from 'types/countries';
import defaultPopularCitiesData from 'components/suggested-location-dropdown/data.json';
import type { SearchSuggestions } from 'components/suggested-location-dropdown';

/**
 * Returns a fallback array of popular cities based on theme & site location.
 */
export function getFallbackPopularCities(
  themeName: ThemeNames,
  siteLocation: CountryCodeList
): SearchSuggestions[] {
  const { usPopularSearches, canadaPopularSearches } = defaultPopularCitiesData;

  switch (themeName) {
  case ThemeNames.EXP_REALTY_CA:
    return canadaPopularSearches;
  case ThemeNames.EXP_REALTY_US:
  case ThemeNames.EXP_IN_SERVICE:
    return usPopularSearches;
  case ThemeNames.ZOOCASA:
    return siteLocation === CountryCodeList.CANADA
      ? canadaPopularSearches
      : usPopularSearches;
  default:
    // If no match, just return based on site location
    return siteLocation === CountryCodeList.CANADA
      ? canadaPopularSearches
      : usPopularSearches;
  }
}
